<template>
    <div>
        <app-header @getHeight="getHeight"></app-header>

        <div style="background-color: #fafafa">
            <div class="max_width min_width">
                <div class="flex align_center padtb_20" :style="{ 'margin-top': offsetHeight + 'px' }">
                    <div class="f838 size_14">当前位置：</div>
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/demand' }">需方市场</el-breadcrumb-item>
                        <!-- <el-breadcrumb-item class="d8f">政策法规</el-breadcrumb-item> -->
                        <el-breadcrumb-item class="" style="color: #ff6618">填写对接详情</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
        </div>

        <div style="background-color: #fafafa ;padding-bottom: 30px;">
            <div class="max_width min_width">
                <section class="bgfff">
                    <div class="dis align_center">
                        <div class="icon"></div>
                        <div class="size_18 marl_20">填写对接详情</div>
                    </div>
                </section>

                <div class="mart_20">
                    <el-form :label-position="labelPosition" label-width="180px" :model="formLabelAlign" :rules="rules"
                        ref="formLabelAlign">
                        <el-form-item label="响应单位" prop="response_name">
                            <el-input placeholder="请输入联系人" class="width_500"
                                v-model="formLabelAlign.response_name"></el-input>
                        </el-form-item>
                        <el-form-item label="当前状态" prop="response_status">
                            <el-select v-model="formLabelAlign.response_status" placeholder="请选择当前状态" class="width_500">
                                <el-option v-for="item in responseStaus" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="是否满足需求" prop="is_demand">
                            <el-select v-model="formLabelAlign.is_demand" placeholder="请选择是否满足需求" class="width_500">
                                <el-option v-for="item in optionNum" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="响应联系人" prop="response_contact">
                            <el-input placeholder="请输入响应联系人" class="width_500"
                                v-model="formLabelAlign.response_contact"></el-input>
                        </el-form-item>
                        <el-form-item label="响应联系人电话" prop="response_phone">
                            <el-input placeholder="请输入响应联系人电话" class="width_500"
                                v-model="formLabelAlign.response_phone"></el-input>
                        </el-form-item>
                    </el-form>

                    <el-button @click="add()" style="background-color: #ff7129;color: #fefefe;
                margin-top: 30px;
              ">确认添加</el-button>
                </div>
            </div>


        </div>


        <app-footer></app-footer>
    </div>
</template>

<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
export default {
    data() {
        return {
            offsetHeight: "",
            labelPosition: "left",
            id:"",
            optionNum: [{
                value: 1,
                label: "是"
            },
            {
                value: 2,
                label: "否"
            },
            ],

            responseStaus: [
                {
                    value: 1,
                    label: "正在对接"
                },
                {
                    value: 2,
                    label: "达成意向"
                },
                {
                    value: 3,
                    label: "签订合同"
                },
            ],
            formLabelAlign: {
                is_demand: "",
                response_contact: "",
                response_phone: "",
                response_name: "",
                response_status: ""
            },
            rules: {
                is_demand: [{
                    required: true,
                    message: "请选择是否满足需求"
                }],
                response_name: [{
                    required: true,
                    message: "请输入响应单位",
                    trigger: "blur"
                },
                {
                    required: true,
                    message: "响应单位不能为空",
                    trigger: "change"
                },
                ],
                response_status: [{
                    required: true,
                    message: "请选择当前状态"
                }],
                response_contact: [{
                    required: true,
                    message: "请输入响应联系人",
                    trigger: "blur"
                },
                {
                    required: true,
                    message: "响应联系人不能为空",
                    trigger: "change"
                },
                ],
                response_phone: [{
                    required: true,
                    message: "请输入响应联系人电话",
                    trigger: "blur"
                },
                {
                    required: true,
                    message: "响应联系人电话不能为空",
                    trigger: "change"
                },
                ],
            }
        }
    },
    created(){
        this.id = this.$route.query.id;
    },
    methods: {
        //获取高度
        getHeight(data) {
            console.log(data, "data");
            this.offsetHeight = data;
        },
        add() {
            this.$refs["formLabelAlign"].validate((valid) => {
                if (valid) {
                    let data = {
                        token: localStorage.eleToken,
                        response_name:this.formLabelAlign.response_name,
                        response_status:this.formLabelAlign.response_status,
                        response_contact:this.formLabelAlign.response_contact,
                        response_phone:this.formLabelAlign.response_phone,
                        is_demand:this.formLabelAlign.is_demand,
                        id:this.id,

                    };
                    console.log(data, "data");

                    this.$post("demand_upd", data).then((res) => {
                        this.$message({
                            message: res.msg,
                            type: "success",
                            duration: 1500,
                        });
                        this.$router.push("/demand");
                    console.log(res,'res')
                    });
                }
            })
        }

    },
    components: {
        "app-header": Header,
        "app-footer": Footer,
    },
}
</script>

<style>
.width_500 {
    width: 500px;
}
</style>